#root {
    width: 100vw;
    height: 100vh;
    display: flex;
}

/* Customize CSS */
.dzu-previewContainer {
    max-width: 400px;
    overflow: hidden;
    display: inline-block;
    position: relative;
}

@media only screen and (max-width: 768px) {
    .dzu-previewContainer {
        max-width: 100%;
    }
}

.dzu-previewImage {
    max-width: 100%;
    max-height: 100%;
}

.dzu-dropzoneDisabled .chooseFile {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: transparent;
    color: rgba(0, 0, 0, 0.26);
    cursor: default;
    pointer-events: none;
}

.dzu-dropzone {
    position: relative;
}

.dzu-input {
    display: none;
}

.dzu-submitButton {
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Prompt", "Helvetica", "Arial", sans-serif;
    font-weight: "normal";
    line-height: 1.75;
    border-radius: 8px;
    letter-spacing: 0.02857em;
    color: #02263d;
    background-color: #ffc107;
    cursor: pointer;
    border: 0;
}

.dzu-submitButton:hover {
    background-color: #b3881f;
}

/* Need to customize material table*/
.MTableToolbar-root-27 {
    padding: 0 !important;
}

.hide-input-arrows input[type="number"]::-webkit-inner-spin-button,
.hide-input-arrows input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.MuiExpansionPanelDetails-root .MuiListItem-button {
    font-size: 14px;
    color: grey;
}

/* Custom input */
.custom-input-gray {
    border-radius: 8px;
    background-color: #f6f7f8;
    color: #616161;
    height: 36px;
    flex-direction: row !important;
}

.custom-input-gray .MuiInput-root:not(.MuiInputBase-adornedEnd) > :first-child {
    padding: 0 8px;
}

.custom-input-gray
    .MuiInput-root.MuiInputBase-adornedEnd
    > .MuiInputAdornment-positionEnd {
    margin: 0 8px 0 0;
}

.custom-input-gray .MuiInput-root.MuiInputBase-adornedEnd > input {
    padding: 0 2px 0 8px;
}

.custom-input-gray .MuiInputBase-input {
    font-size: 0.875rem;
    line-height: 1.1876em;
    padding: 5px 0 4px;
}

.custom-input-gray .MuiInputBase-input.MuiSelect-root {
    padding-left: 8px;
}

.custom-input-gray-numeric .MuiInputBase-input {
    text-align: right;
}

.MuiInputLabel-filled + .MuiInputBase-root:not(.MuiFilledInput-underline) {
    border-radius: 8px;
}

.MuiButton-root {
    font-size: 15px !important;
    min-height: 42px !important;
}
